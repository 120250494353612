<template>
  <div class="view reports-view container">
    <h1 class="title">Logs</h1>
    <div>
      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
      <CampaignStatus
       :reportData="reportData">
      </CampaignStatus>
    </div>
  </div>
</template>

<script>
import CampaignStatus from '../../components/Reports/CampaignStatus';
import reports from '../../services/reports';

export default {
    name: 'Logs',

    components: {
        CampaignStatus
    },

    data() {
        return {
            isLoading: true,
            reportData: []
        };
    },

    created() {
        this.fetchLogs();
    },

    methods: {
        fetchLogs() {
            this.isLoading = true;
            reports.getCampaignStatus().then(resp => {
                this.reportData = resp.data;
            })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>

<template>
  <form>
    <b-field label="Name">
      <b-input
       type="text"
       v-model="form.name"
       autocomplete="off"
       maxlength="100"
       :has-counter="false"
       required>
      </b-input>
    </b-field>
    <b-field position="is-right">
      <b-button
       label="Preview"
       type="is-text"
       class="is-pulled-right"
       size="is-small"
       @click="previewHtml">
      </b-button>
    </b-field>
    <b-field label="HTML Content">
      <b-input
       type="textarea"
       rows="10"
       v-model="form.htmlContent"
       autocomplete="off"
       required>
      </b-input>
    </b-field>
    <b-field position="is-right">
      <b-button
       label="Preview"
       type="is-text"
       class="is-pulled-right"
       size="is-small"
       @click="previewText">
      </b-button>
    </b-field>
    <b-field position="is-right">
      <b-button
       label="Word Wrap"
       type="is-text"
       class="is-pulled-right"
       size="is-small"
       @click="wrap">
      </b-button>
    </b-field>
    <b-field label="Text Content">
      <b-input
       type="textarea"
       rows="10"
       v-model="form.textContent"
       autocomplete="off"
       required>
      </b-input>
    </b-field>
    <b-field v-if="form.id">
      <b-checkbox
       v-model="form.delete"
       autocomplete="off">
        Delete
      </b-checkbox>
    </b-field>
    <b-field grouped>
      <p class="control">
        <b-button
         label="Submit"
         :type="form.delete ? 'is-danger' : 'is-primary'"
         @click="onSubmit">
      </b-button>
      </p>
      <p class="control">
        <b-button
         label="Cancel"
         type="is-text"
         @click="onCancel">
      </b-button>
      </p>
    </b-field>
    <b-modal v-model="showPreview">
      <preview-view
       :type="previewType"
       :content="previewContent">
      </preview-view>
    </b-modal>
  </form>
</template>

<script>
import PreviewView from '../PreviewView';

export default {
    name: 'TemplateForm',

    props: [
        'template'
    ],

    components: {
        PreviewView
    },

    data() {
        return {
            form: {},
            previewType: 'text',
            previewContent: '',
            showPreview: false
        };
    },

    created() {
        this.form = {
            id: this.template.id, 
            name: this.template.name,
            htmlContent: this.template.htmlContent,
            textContent: this.template.textContent,
            delete: false
        };
    },

    methods: {
        // stackoverflow.com/a/51506718
        wrap() {
            this.form.textContent = this.form.textContent.replace(
                /(?![^\n]{1,78}$)([^\n]{1,78})\s/g, '$1\n'
            );
        },

        previewHtml() {
            this.previewType = 'html';
            this.previewContent = this.form.htmlContent;
            this.showPreview = true;
        },

        previewText() {
            this.previewType = 'text';
            this.previewContent = this.form.textContent;
            this.showPreview = true;
        },

        onSubmit() {
            this.$emit('submit', this.form);
        },

        onCancel() {
            this.$emit('cancel');
        }
    }
};
</script>

<template>
  <div class="view tags-view container">
    <div class="columns align-items-flex-end">
      <div class="column">
        <h1 class="title">Tags</h1>
        <h2 class="subtitle">
          Manage tags
        </h2>
      </div>
      <div class="column">
        <b-button
         v-if="!tagFormActive"
         class="is-pulled-right"
         type="is-primary"
         @click="onButtonClick">
          New Tag
        </b-button>
      </div>
    </div>
    <div>
      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
      <TagSummary
       v-if="!tagFormActive"
       :tagList="tagList"
       @click="onSummaryClick">
      </TagSummary>
      <TagForm
       v-if="tagFormActive"
       :tag="tag"
       @submit="onFormSubmit"
       @cancel="onFormCancel">
      </TagForm>
    </div>
  </div>
</template>

<script>
import TagForm from '../../components/TagForm';
import TagSummary from '../../components/TagSummary';
import tags from '../../services/tags';

export default {
    name: 'Tags',

    components: {
        TagForm,
        TagSummary
    },

    data() {
        return {
            isLoading: true,
            tag: {},
            tagList: [],
            tagFormActive: false,
        };
    },

    created() {
        this.fetchTags();
    },

    methods: {
        onButtonClick() {
            this.tag = {
                id: 0,
                name: '',
                fgColor: '#000000',
                bgColor: '#FFFFFF'
            };
            this.tagFormActive = true;
        },

        onSummaryClick(tag) {
            this.isLoading = true;
            tags.get(tag.id).then(resp => {
                this.tag = resp.data;
                this.tagFormActive = true;
            })
                .catch(error => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        onFormCancel() {
            this.tagFormActive = false;
        },

        onFormSubmit(tag) {
            this.isLoading = true;
            let service;
            if (tag.id) {
                if (tag.delete) {
                    service = tags.delete(tag);
                } else {
                    service = tags.update(tag);
                }
            } else {
                service = tags.create(tag);
            }

            service.then(() => {
                this.tagFormActive = false;
                return this.fetchTags();
            })
                .catch(error => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                });
        },

        fetchTags() {
            this.isLoading = true;
            tags.getSummary().then(resp => {
                this.tagList = resp.data;
            })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>

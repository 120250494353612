<template>
  <form>
    <b-field label="Name">
      <b-input
       type="text"
       v-model="form.name"
       autocomplete="off"
       maxlength="50"
       :has-counter="false"
       required>
      </b-input>
    </b-field>
    <div class="columns">
      <div class="column is-one-third">
        <b-field label="Background Color">
          <b-input
           type="color"
           v-model="form.bgColor"
           autocomplete="off"
           required>
          </b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <b-field label="Foreground Color">
          <b-input
           type="color"
           v-model="form.fgColor"
           autocomplete="off"
           required>
          </b-input>
        </b-field>
      </div>
    </div>
    <b-field v-if="form.id">
      <b-checkbox
       v-model="form.delete"
       autocomplete="off">
        Delete
      </b-checkbox>
    </b-field>
    <b-field grouped>
      <p class="control">
        <b-button
         label="Submit"
         :type="form.delete ? 'is-danger' : 'is-primary'"
         @click="onSubmit">
        </b-button>
      </p>
      <p class="control">
        <b-button
         label="Cancel"
         type="is-text"
         @click="onCancel">
        </b-button>
      </p>
    </b-field>
  </form>
</template>

<script>
export default {
    name: 'TagForm',

    props: [
        'tag'
    ],

    data() {
        return {
            form: {}
        };
    },

    created() {
        this.form = {
            id: this.tag.id, 
            name: this.tag.name,
            fgColor: this.tag.fgColor,
            bgColor: this.tag.bgColor,
            delete: false
        };
    },

    methods: {
        onSubmit() {
            this.$emit('submit', this.form);
        },

        onCancel() {
            this.$emit('cancel');
        }
    }
};
</script>

<template>
  <iframe
   style="height: 95vh; width: 100%;"
   :srcdoc="previewContent">
  </iframe>
</template>

<script>

export default {
    name: 'PreviewView',

    props: [
        'type',
        'content'
    ],

    data() {
        return {
            previewContent: ''
        };
    },

    created() {
        if (this.type == 'text') {
            let content = this.content
                .replaceAll('&', '&amp;')
                .replaceAll('<', '&lt;')
                .replaceAll('>', '&gt;');
            this.previewContent = '<pre>' + content;
        } else {
            this.previewContent = this.content;
        }
    }
};
</script>

<style scoped>
iframe {
  color: #000;
  background-color: #fff;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c(
        "b-field",
        { attrs: { label: "Name" } },
        [
          _c("b-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              maxlength: "100",
              "has-counter": false,
              required: ""
            },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: "Description" } },
        [
          _c("b-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              maxlength: "100",
              "has-counter": false,
              required: ""
            },
            model: {
              value: _vm.form.description,
              callback: function($$v) {
                _vm.$set(_vm.form, "description", $$v)
              },
              expression: "form.description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: "Content" } },
        [
          _c("editor", {
            attrs: { init: _vm.editorInit },
            model: {
              value: _vm.form.content,
              callback: function($$v) {
                _vm.$set(_vm.form, "content", $$v)
              },
              expression: "form.content"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.form.id
        ? _c(
            "b-field",
            [
              _c(
                "b-checkbox",
                {
                  attrs: { autocomplete: "off" },
                  model: {
                    value: _vm.form.delete,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "delete", $$v)
                    },
                    expression: "form.delete"
                  }
                },
                [_vm._v("\n      Delete\n    ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-field", { attrs: { grouped: "" } }, [
        _c(
          "p",
          { staticClass: "control" },
          [
            _c("b-button", {
              attrs: {
                label: "Submit",
                type: _vm.form.delete ? "is-danger" : "is-primary"
              },
              on: { click: _vm.onSubmit }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "control" },
          [
            _c("b-button", {
              attrs: { label: "Cancel", type: "is-text" },
              on: { click: _vm.onCancel }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
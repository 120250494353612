import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowUp, faArrowDown, faAngleLeft, faAngleRight, faInfoCircle } 
  from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowUp, faArrowDown, faAngleLeft, faAngleRight, faInfoCircle);
Vue.component('vue-fontawesome', FontAwesomeIcon);

import Buefy from 'buefy';
Vue.use(Buefy, {
  defaultToastDuration: 3000,
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas"
});

import axios from 'axios';
Vue.prototype.$http = axios;

// Attach authorization header to requests if auth token exists
// and set loading indicator
axios.interceptors.request.use((request) => {
  const token = store.getters['auth/token'];
  if (token) {
    request.headers.Authorization = 'Bearer ' + token;
  }
  return request;
});

// Clear loading indicator, Attempt to renew an expired auth token
// and re-issue a failed request
axios.interceptors.response.use(
    (resp) => {
      return resp;
    },
    (error) => {
      // take no action for auth routes and non-noauth failures
      if (error.config.url.startsWith('/api/auth') ||
            error.response.status != 401
      ) {
        return Promise.reject(error);
      }

      return axios.post('/api/auth/renew')
          .then((resp) => {
            return store.dispatch('auth/setToken', resp.data.token)
                .then(() => {
                  return axios.request(error.config);
                });
          })
          .catch((error) => {
            return Promise.reject(error);
          });
    },
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

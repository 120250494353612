var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view templates-view container" }, [
    _c("div", { staticClass: "columns align-items-flex-end" }, [
      _c("div", { staticClass: "column" }, [
        _c("h1", { staticClass: "title" }, [_vm._v("Templates")]),
        _vm._v(" "),
        _c("h2", { staticClass: "subtitle" }, [
          _vm._v("\n        Manage templates\n        "),
          _c(
            "span",
            { on: { click: _vm.showHelp } },
            [
              _c("b-icon", {
                attrs: {
                  icon: "info-circle",
                  type: "is-primary",
                  size: "is-small"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          !_vm.templateFormActive
            ? _c(
                "b-button",
                {
                  staticClass: "is-pulled-right",
                  attrs: { type: "is-primary" },
                  on: { click: _vm.onButtonClick }
                },
                [_vm._v("\n        New Template\n      ")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("b-loading", {
          attrs: { "is-full-page": false },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        }),
        _vm._v(" "),
        !_vm.templateFormActive
          ? _c("TemplateSummary", {
              attrs: { templateList: _vm.templateList },
              on: { click: _vm.onSummaryClick }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.templateFormActive
          ? _c("TemplateForm", {
              attrs: { template: _vm.template },
              on: { submit: _vm.onFormSubmit, cancel: _vm.onFormCancel }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
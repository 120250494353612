<template>
  <div>
    <div v-show="show == 'login'">
      <b-field label="Email Address"> 
        <b-input
         v-model="form.email"
         type="email"
         maxlength="100"
         :has-counter="false">
        </b-input>
      </b-field>
      <b-field label="Password">
        <b-input
         v-model="form.password"
         type="password"
         maxlength="100"
         :has-counter="false">
        </b-input>
      </b-field>
      <hr>
      <b-field grouped>
        <p class="control">
          <b-button
           label="Login"
           type="is-primary"
           @click="onLogin">
          </b-button>
        </p>
        <p class="control">
          <b-button
           label="Forgot Password"
           type="is-text"
           @click="onForgotPassword">
          </b-button>
        </p>
      </b-field>
    </div>
    <div v-show="show == 'email'">
      <b-message type="is-info">
        Enter your email address below and a reset token will be sent to you.
      </b-message>
      <b-field label="Email Address"> 
        <b-input
         v-model="form.email"
         type="email"
         maxlength="100"
         :has-counter="false">
        </b-input>
      </b-field>
      <hr>
      <b-field grouped>
        <p class="control">
          <b-button
           label="Submit"
           type="is-primary"
           @click="onEmailSubmit">
          </b-button>
        </p>
        <p class="control">
          <b-button
           label="Cancel"
           type="is-text"
           @click="onCancel">
          </b-button>
        </p>
      </b-field>
    </div>
    <div v-show="show == 'reset'">
      <b-message type="is-info">
        Enter the reset token sent to you via email and provide a new password.
      </b-message>
      <b-field label="Token"> 
        <b-input
         v-model="form.token"
         maxlength="100"
         :has-counter="false">
        </b-input>
      </b-field>
      <b-field label="New Password">
        <b-input
         v-model="form.newPassword"
         type="password"
         maxlength="100"
         :has-counter="false">
        </b-input>
      </b-field>
      <b-field label="Confirm Password">
        <b-input
         v-model="form.confirmPassword"
         type="password"
         maxlength="100"
         :has-counter="false">
        </b-input>
      </b-field>
      <hr>
      <b-field grouped>
        <p class="control">
          <b-button
           label="Reset Password"
           type="is-primary"
           @click="onResetPassword">
          </b-button>
        </p>
        <p class="control">
          <b-button
           label="Cancel"
           type="is-text"
           @click="onCancel">
          </b-button>
        </p>
      </b-field>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'LoginForm',

    data() {
        return {
            show: 'login',
            form: {
                email: '',
                password: '',
                token: '',
                newPassword: '',
                confirmPassword: ''
            }
        };
    },

    methods: {
        doLogin(promise) {
            return promise;
        },

        onLogin() {
            axios.post('/api/auth', {
                email: this.form.email,
                password: this.form.password
            })
                .then((resp) => {
                    this.$store.dispatch('auth/setToken', resp.data.token);
                })
                .then(() => {
                    this.$router.push({name: 'Home'});
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        message: 'Error: Login failed',
                        type: 'is-danger'
                    });
                    console.log(error);
                });
        },

        onForgotPassword() {
            this.show = 'email';
        },

        onCancel() {
            this.show = 'login';
            this.form.token = '';
            this.form.newPassword = '';
            this.form.confirmPassword = '';
        },

        onEmailSubmit() {
            axios.post('/api/auth/reset', {email: this.form.email})
                .then(() => {
                    this.show = 'reset';
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                    console.log(error);
                });
        },

        onResetPassword() {
            if (this.form.newPassword != this.form.confirmPassword) {
                this.$buefy.toast.open({
                    message: 'Error: New and confirm passwords do not match',
                    type: 'is-danger'
                });
                return;
            }

            axios.post('/api/auth', {
                email: this.form.email,
                token: this.form.token,
                password: this.form.newPassword
            })
                .then((resp) => {
                    this.$store.dispatch('auth/setToken', resp.data.token);
                })
                .then(() => {
                    this.$buefy.toast.open({
                        message: 'Password change successful',
                        type: 'is-success'
                    });
                    this.$router.push({name: 'Home'});
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                    console.log(error);
                });
        }
    }
};
</script>

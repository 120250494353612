import axios from 'axios';

export default {
  async getSummary() {
    return axios.get('/api/campaigns');
  },
  async get(id) {
    return axios.get(`/api/campaigns/${id}`);
  },
  async create(campaign) {
    return axios.post('/api/campaigns', campaign);
  },
  async update(campaign) {
    return axios.put(`/api/campaigns/${campaign.id}`, campaign);
  },
  async delete(campaign) {
    return axios.delete(`/api/campaigns/${campaign.id}`);
  },
  async send(campaign, data) {
    return axios.post(`/api/campaigns/${campaign.id}/send`, data);
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show == "login",
            expression: "show == 'login'"
          }
        ]
      },
      [
        _c(
          "b-field",
          { attrs: { label: "Email Address" } },
          [
            _c("b-input", {
              attrs: { type: "email", maxlength: "100", "has-counter": false },
              model: {
                value: _vm.form.email,
                callback: function($$v) {
                  _vm.$set(_vm.form, "email", $$v)
                },
                expression: "form.email"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Password" } },
          [
            _c("b-input", {
              attrs: {
                type: "password",
                maxlength: "100",
                "has-counter": false
              },
              model: {
                value: _vm.form.password,
                callback: function($$v) {
                  _vm.$set(_vm.form, "password", $$v)
                },
                expression: "form.password"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("b-field", { attrs: { grouped: "" } }, [
          _c(
            "p",
            { staticClass: "control" },
            [
              _c("b-button", {
                attrs: { label: "Login", type: "is-primary" },
                on: { click: _vm.onLogin }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "control" },
            [
              _c("b-button", {
                attrs: { label: "Forgot Password", type: "is-text" },
                on: { click: _vm.onForgotPassword }
              })
            ],
            1
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show == "email",
            expression: "show == 'email'"
          }
        ]
      },
      [
        _c("b-message", { attrs: { type: "is-info" } }, [
          _vm._v(
            "\n      Enter your email address below and a reset token will be sent to you.\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Email Address" } },
          [
            _c("b-input", {
              attrs: { type: "email", maxlength: "100", "has-counter": false },
              model: {
                value: _vm.form.email,
                callback: function($$v) {
                  _vm.$set(_vm.form, "email", $$v)
                },
                expression: "form.email"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("b-field", { attrs: { grouped: "" } }, [
          _c(
            "p",
            { staticClass: "control" },
            [
              _c("b-button", {
                attrs: { label: "Submit", type: "is-primary" },
                on: { click: _vm.onEmailSubmit }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "control" },
            [
              _c("b-button", {
                attrs: { label: "Cancel", type: "is-text" },
                on: { click: _vm.onCancel }
              })
            ],
            1
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show == "reset",
            expression: "show == 'reset'"
          }
        ]
      },
      [
        _c("b-message", { attrs: { type: "is-info" } }, [
          _vm._v(
            "\n      Enter the reset token sent to you via email and provide a new password.\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Token" } },
          [
            _c("b-input", {
              attrs: { maxlength: "100", "has-counter": false },
              model: {
                value: _vm.form.token,
                callback: function($$v) {
                  _vm.$set(_vm.form, "token", $$v)
                },
                expression: "form.token"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "New Password" } },
          [
            _c("b-input", {
              attrs: {
                type: "password",
                maxlength: "100",
                "has-counter": false
              },
              model: {
                value: _vm.form.newPassword,
                callback: function($$v) {
                  _vm.$set(_vm.form, "newPassword", $$v)
                },
                expression: "form.newPassword"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Confirm Password" } },
          [
            _c("b-input", {
              attrs: {
                type: "password",
                maxlength: "100",
                "has-counter": false
              },
              model: {
                value: _vm.form.confirmPassword,
                callback: function($$v) {
                  _vm.$set(_vm.form, "confirmPassword", $$v)
                },
                expression: "form.confirmPassword"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("b-field", { attrs: { grouped: "" } }, [
          _c(
            "p",
            { staticClass: "control" },
            [
              _c("b-button", {
                attrs: { label: "Reset Password", type: "is-primary" },
                on: { click: _vm.onResetPassword }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "control" },
            [
              _c("b-button", {
                attrs: { label: "Cancel", type: "is-text" },
                on: { click: _vm.onCancel }
              })
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <nav class="navbar is-primary">
    <div class="navbar-brand">
      <router-link :to="{name: 'Home'}" class="navbar-item brand-text">
        <h1>Home</h1>
      </router-link>
      <span @click="burgerOpen = !burgerOpen" class="navbar-burger burger">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </div>
    <div class="navbar-menu" :class="burgerOpen ? 'is-active' : ''">
      <div class="navbar-start">
        <router-link :to="{name: 'Campaigns'}" class="navbar-item">Campaigns</router-link>
        <router-link :to="{name: 'Templates'}" class="navbar-item">Templates</router-link>
        <div class="navbar-item has-dropdown is-hoverable">
          <div class="navbar-link">System</div>
          <div class="navbar-dropdown">
            <router-link :to="{name: 'Accounts'}" class="navbar-item">Accounts</router-link>
            <router-link :to="{name: 'Snippets'}" class="navbar-item">Snippets</router-link>
            <router-link :to="{name: 'Tags'}" class="navbar-item">Tags</router-link>
            <router-link :to="{name: 'Logs'}" class="navbar-item">Logs</router-link>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <div class="navbar-link">Account</div>
          <div class="navbar-dropdown">
            <router-link :to="{name: 'Password'}" class="navbar-item">Password</router-link>
          </div>
        </div>
      </div>
      <div class="navbar-end">
        <a @click.prevent="logout" class="navbar-item">Logout</a>
      </div>
    </div>
  </nav>
</template>

<script>
import AuthService from '../../services/auth';

export default {
    name: 'App',

    data() {
        return {
            burgerOpen: false
        };
    },

    methods: {
        logout() {
            AuthService.logout();
        }
    }
};
</script>

<style scoped>
.navbar {
  margin-bottom: 20px;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view accounts-view container" }, [
    _c("div", { staticClass: "columns align-items-flex-end" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          !_vm.accountFormActive
            ? _c(
                "b-button",
                {
                  staticClass: "is-pulled-right",
                  attrs: { type: "is-primary" },
                  on: { click: _vm.onButtonClick }
                },
                [_vm._v("\n        New Account\n      ")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("b-loading", {
          attrs: { "is-full-page": false },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        }),
        _vm._v(" "),
        !_vm.accountFormActive
          ? _c("AccountSummary", {
              attrs: { accountList: _vm.accountList },
              on: { click: _vm.onSummaryClick }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.accountFormActive
          ? _c("AccountForm", {
              attrs: { userId: _vm.userId, account: _vm.account },
              on: { submit: _vm.onFormSubmit, cancel: _vm.onFormCancel }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Accounts")]),
      _vm._v(" "),
      _c("h2", { staticClass: "subtitle" }, [
        _vm._v("\n        Manage accounts\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="view password-view container">
    <h1 class="title">Account: Change Password</h1>
    <h2 class="subtitle">Change your password</h2>
    <PasswordForm></PasswordForm>
  </div>
</template>

<script>
import PasswordForm from '../../components/PasswordForm';

export default {
    name: 'Password',

    components: {
        PasswordForm
    }
};
</script>

<template>
  <div class="campaign-summary-container">
    <b-table
     v-if="campaignList.length"
     paginated
     per-page="10"
     :selected.sync="selected"
     :data="campaignList"
     :hoverable="true"
     @click="onClick">
      <b-table-column
       label="Name"
       field="name"
       searchable
       sortable
       v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
       label="Tag"
       field="tag.name"
       searchable
       sortable
       v-slot="props">
        <b-tag
         v-if="props.row.tagId != 0"
         :style="{color: props.row.tag.fgColor, backgroundColor: props.row.tag.bgColor}">
          {{ props.row.tag.name }}
        </b-tag>
      </b-table-column>
    </b-table>
    <div v-else>
      <b>No campaigns available</b>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CampaignSummary',

    props: [
        'campaignList'
    ],

    data() {
        return {
            selected: {}
        };
    },

    methods: {
        onClick(row) {
            this.$emit('click', row);
        }
    }
};
</script>

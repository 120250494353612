var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n      Send Test Email\n    ")
      ]),
      _vm._v(" "),
      _c("button", {
        staticClass: "delete",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      _vm._l(_vm.fieldList, function(field) {
        return _c(
          "b-field",
          { key: field, attrs: { label: field } },
          [
            _c("b-input", {
              attrs: { type: "text", autocomplete: "off", required: "" },
              model: {
                value: _vm.form[field],
                callback: function($$v) {
                  _vm.$set(_vm.form, field, $$v)
                },
                expression: "form[field]"
              }
            })
          ],
          1
        )
      }),
      1
    ),
    _vm._v(" "),
    _c(
      "footer",
      { staticClass: "modal-card-foot" },
      [
        _c("b-button", {
          attrs: { label: "Send", type: "is-primary" },
          on: { click: _vm.submit }
        }),
        _vm._v(" "),
        _c("b-button", {
          attrs: { label: "Cancel" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Send Test Email
      </p>
      <button
       type="button"
       class="delete"
       @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <b-field v-for="field in fieldList"
       :key="field"
       :label="field">
        <b-input
         type="text"
         v-model="form[field]"
         autocomplete="off"
         required>
        </b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-button
       label="Send"
       type="is-primary"
       @click="submit">
      </b-button>
      <b-button
       label="Cancel"
       @click="$emit('close')">
      </b-button>
    </footer>
  </div>
</template>

<script>
export default {
    name: 'TestFieldsForm',

    props: [
        'fieldList'
    ],

    data() {
        return {
            form: {}
        };
    },

    methods: {
        submit() {
            this.$emit('submit', this.form);
            this.$emit('close');
        }
    }
};
</script>

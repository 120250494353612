<template>
  <div class="view send-campaigns-view container">
    <h1 class="title">Welcome!</h1>
    <h2 class="subtitle">
      Send a campaign
      <span @click="showHelp">
        <b-icon
         icon="info-circle"
         type="is-primary"
         size="is-small">
        </b-icon>
      </span>
    </h2>
    <div>
      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
      <b-tabs v-model="activeTab">
        <b-tab-item label="1. Select Campaign">
          <CampaignSummary
           :campaignList="campaignList"
           :tagList="tagList"
           @click="onSummaryClick">
          </CampaignSummary>
        </b-tab-item>
        <b-tab-item label="2. Specify Recipient Details">
          <CsvUploadForm
           @submit="onFormSubmit">
          </CsvUploadForm>
        </b-tab-item>
        <b-tab-item label="3. Send Emails">
          <b-message type="is-info">
            <p><b>Current campaign:</b> {{campaign.name ? campaign.name : "--"}}</p>
            <p><b>Recipients:</b> {{recipients.length}}</p>
          </b-message>
          <p>
            <b-button
             type="is-primary"
             :disabled="!(campaign.name && recipients.length)"
             @click="onSendTestClick">
             Send test to single recipient
            </b-button>
            <b-button
             type="is-danger"
             :disabled="!(campaign.name && recipients.length)"
             @click="sendAll">
              Send to all recipients
            </b-button>
          </p>
        </b-tab-item>
      </b-tabs>
      <b-modal
       v-model="testFieldsActive"
       has-modal-card
       trap-focus
       aria-role="dialog"
       aria-modal>
        <template #default="props">
          <test-fields-form
           :fieldList="testFields"
           @close="props.close"
           @submit="sendTest">
          </test-fields-form>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import CampaignSummary from '../../components/CampaignSummary';
import CsvUploadForm from '../../components/CsvUploadForm';
import campaigns from '../../services/campaigns';
import tags from '../../services/tags';
import TestFieldsForm from '../../components/TestFieldsForm';

export default {
    name: 'SendCampaign',

    components: {
        CampaignSummary,
        CsvUploadForm,
        TestFieldsForm
    },

    data() {
        return {
            isLoading: true,
            activeTab: 0,
            campaignList: [],
            tagList: [],
            campaign: {id: 0, name: ''},
            recipients: [],
            testFieldsActive: false,
            testFields: []
        };
    },

    created() {
        this.isLoading = true;
        campaigns.getSummary().then(resp => {
            this.campaignList = resp.data;
        })
            .then(() => {
                tags.getSummary().then(resp => {
                    this.tagList = resp.data;
                });
            })
            .catch(error => {
                this.$buefy.toast.open({
                    message: 'Error: ' + error.response.data.error,
                    type: 'is-danger'
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    methods: {
        onSummaryClick(data) {
            this.campaign = data;
        },

        onFormSubmit(data) {
            this.recipients = data;
        },

        onSendTestClick() {
            this.testFields = Object.keys(this.recipients[0]);
            this.testFieldsActive = true;
        },

        sendTest(testFields) {
            this.send([testFields]);
        },

        sendAll() {
            this.send(this.recipients);
        },

        send(recipients) {
            this.isLoading = true;
            campaigns.send(this.campaign, {data: recipients})
                .then(resp => {
                    if (resp.status == 202) {
                        this.$buefy.toast.open({
                            message: 'Campaign sent',
                            type: 'is-success'
                        });
                        return;
                    }
                })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        showHelp() {
            this.$buefy.dialog.alert(`
<div class="content">
<p>Send a campaign using the following process:</p>

<ol>
<li>Select the campaign to send</li>
<li>Upload a CSV containing fields to replace the message’s
 tags</li>
<li>Send a test campaign</li>
<li>Send the campaign to all recipients</li>
</ol>

<p>At minimum, the CSV must contain <code>firstName</code>,
<code>lastName</code>, and <code>email</code> columns. Tags
that are defined in the message but have no matching CSV
column will be left unreplaced.</p>

<p>Sending the test message will prompt you for specific
replacement values. Enter values pertinent to yourself
 (especially your email address) so that you can inspect the
email as others would see it before sending it to all
recipients.</p>

<p><b>ALWAYS REVIEW THE TEST EMAIL! SENT EMAILS CANNOT BE
UNSENT!</b></p>
</div>`
            );
        }
    }
};
</script>

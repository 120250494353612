import axios from 'axios';

export default {
  async getSummary() {
    return axios.get('/api/snippets');
  },
  async get(id) {
    return axios.get(`/api/snippets/${id}`);
  },
  async create(snippet) {
    return axios.post('/api/snippets', snippet);
  },
  async update(snippet) {
    return axios.put(`/api/snippets/${snippet.id}`, snippet);
  },
  async delete(snippet) {
    return axios.delete(`/api/snippets/${snippet.id}`);
  }
}

import axios from 'axios';

export default {
  async getSummary() {
    return axios.get('/api/templates');
  },
  async get(id) {
    return axios.get(`/api/templates/${id}`);
  },
  async create(template) {
    return axios.post('/api/templates', template);
  },
  async update(template) {
    return axios.put(`/api/templates/${template.id}`, template);
  },
  async delete(template) {
    return axios.delete(`/api/templates/${template.id}`);
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view send-campaigns-view container" }, [
    _c("h1", { staticClass: "title" }, [_vm._v("Welcome!")]),
    _vm._v(" "),
    _c("h2", { staticClass: "subtitle" }, [
      _vm._v("\n    Send a campaign\n    "),
      _c(
        "span",
        { on: { click: _vm.showHelp } },
        [
          _c("b-icon", {
            attrs: { icon: "info-circle", type: "is-primary", size: "is-small" }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("b-loading", {
          attrs: { "is-full-page": false },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        }),
        _vm._v(" "),
        _c(
          "b-tabs",
          {
            model: {
              value: _vm.activeTab,
              callback: function($$v) {
                _vm.activeTab = $$v
              },
              expression: "activeTab"
            }
          },
          [
            _c(
              "b-tab-item",
              { attrs: { label: "1. Select Campaign" } },
              [
                _c("CampaignSummary", {
                  attrs: {
                    campaignList: _vm.campaignList,
                    tagList: _vm.tagList
                  },
                  on: { click: _vm.onSummaryClick }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-tab-item",
              { attrs: { label: "2. Specify Recipient Details" } },
              [_c("CsvUploadForm", { on: { submit: _vm.onFormSubmit } })],
              1
            ),
            _vm._v(" "),
            _c(
              "b-tab-item",
              { attrs: { label: "3. Send Emails" } },
              [
                _c("b-message", { attrs: { type: "is-info" } }, [
                  _c("p", [
                    _c("b", [_vm._v("Current campaign:")]),
                    _vm._v(
                      " " + _vm._s(_vm.campaign.name ? _vm.campaign.name : "--")
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("b", [_vm._v("Recipients:")]),
                    _vm._v(" " + _vm._s(_vm.recipients.length))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          type: "is-primary",
                          disabled: !(
                            _vm.campaign.name && _vm.recipients.length
                          )
                        },
                        on: { click: _vm.onSendTestClick }
                      },
                      [
                        _vm._v(
                          "\n           Send test to single recipient\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          type: "is-danger",
                          disabled: !(
                            _vm.campaign.name && _vm.recipients.length
                          )
                        },
                        on: { click: _vm.sendAll }
                      },
                      [
                        _vm._v(
                          "\n            Send to all recipients\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("b-modal", {
          attrs: {
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-modal": ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c("test-fields-form", {
                    attrs: { fieldList: _vm.testFields },
                    on: { close: props.close, submit: _vm.sendTest }
                  })
                ]
              }
            }
          ]),
          model: {
            value: _vm.testFieldsActive,
            callback: function($$v) {
              _vm.testFieldsActive = $$v
            },
            expression: "testFieldsActive"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "password-form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Current Password")]),
          _vm._v(" "),
          _c("div", { staticClass: "control" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.oldPassword,
                  expression: "form.oldPassword"
                }
              ],
              staticClass: "input",
              attrs: { type: "password", maxlength: "100" },
              domProps: { value: _vm.form.oldPassword },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "oldPassword", $event.target.value)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("New Pasword")]),
          _vm._v(" "),
          _c("div", { staticClass: "control" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.newPassword,
                  expression: "form.newPassword"
                }
              ],
              staticClass: "input",
              attrs: { type: "password", maxlength: "100" },
              domProps: { value: _vm.form.newPassword },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "newPassword", $event.target.value)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Confirm Pasword")]),
          _vm._v(" "),
          _c("div", { staticClass: "control" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.confirmPassword,
                  expression: "form.confirmPassword"
                }
              ],
              staticClass: "input",
              attrs: { type: "password", maxlength: "100" },
              domProps: { value: _vm.form.confirmPassword },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "confirmPassword", $event.target.value)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _vm._m(0)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "control" }, [
        _c("input", {
          staticClass: "button is-primary",
          attrs: { type: "submit", value: "Submit" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
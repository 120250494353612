import jwtDecode from 'jwt-decode';

export default {
  namespaced: true,

  state: {
    token: '',
    decodedToken: {},
  },

  getters: {
    token: (state) => {
      return state.token;
    },

    userId: (state) => {
      return (state.decodedToken && state.decodedToken.sub) ?
            state.decodedToken.sub : 0;
    },

    isExpired: (state) => {
      return !(state.decodedToken &&
                state.decodedToken.exp &&
                state.decodedToken.exp > (Date.now() / 1000));
    },
  },

  actions: {
    setToken: ({commit}, token) => {
      let decodedToken;
      return new Promise((resolve, reject) => {
        try {
          decodedToken = jwtDecode(token);
        } catch (error) {
          reject(error);
        }

        commit('setToken', token);
        commit('setDecodedToken', decodedToken);
        resolve();
      });
    },

    clearToken: ({commit}) => {
      return new Promise((resolve) => {
        commit('setToken', '');
        commit('setDecodedToken', {});
        resolve();
      });
    },
  },

  mutations: {
    setToken: (state, token) => {
      state.token = token;
    },

    setDecodedToken: (state, decodedToken) => {
      state.decodedToken = decodedToken;
    },
  },
};

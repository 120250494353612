import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Login from '../views/Login';
import SendCampaign from '../views/SendCampaign';
import Accounts from '../views/Accounts';
import Snippets from '../views/Snippets';
import Tags from '../views/Tags';
import Templates from '../views/Templates';
import Campaigns from '../views/Campaigns';
import Password from '../views/Password';
import Logs from '../views/Logs';
import NotFound from '../views/NotFound';

Vue.use(VueRouter);

const isAuthenticated = () => {
    if (!store.getters['auth/isExpired']) {
        return Promise.resolve(true);
    }

    // attempt to renew the auth token
    return axios.post('/api/auth/renew')
        .then((resp) => {
            return store.dispatch('auth/setToken', resp.data.token);
        })
        .then(() => {
            return Promise.resolve(true);
        })
        .catch(() => {
            return Promise.resolve(false);
        });
};

const requireAuth = async (to, from, next) => {
    if (await isAuthenticated()) {
        next();
        return;
    }
    next({name: 'Login', params: {internal: true}});
};

const prohibitAuth = async (to, from, next) => {
    // never restrict if "internal"
    if ((to.params && to.params.internal) || !(await isAuthenticated())) {
        next();
        return;
    }
    next({name: 'Home'});
};

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            name: 'Login',
            path: '/login',
            component: Login,
            beforeEnter: prohibitAuth,
        },
        {
            name: 'Home',
            path: '/',
            component: SendCampaign,
            beforeEnter: requireAuth,
        },
        {
            name: 'Accounts',
            path: '/accounts',
            component: Accounts,
            beforeEnter: requireAuth,
        },
        {
            name: 'Snippets',
            path: '/snippets',
            component: Snippets,
            beforeEnter: requireAuth,
        },
        {
            name: 'Tags',
            path: '/tags',
            component: Tags,
            beforeEnter: requireAuth,
        },
        {
            name: 'Templates',
            path: '/templates',
            component: Templates,
            beforeEnter: requireAuth,
        },
        {
            name: 'Campaigns',
            path: '/campaigns',
            component: Campaigns,
            beforeEnter: requireAuth,
        },
        {
            name: 'Password',
            path: '/password',
            component: Password,
            beforeEnter: requireAuth,
        },
        {
            name: 'Logs',
            path: '/logs',
            component: Logs,
            beforeEnter: requireAuth,
        },
        {
            name: 'NotFound',
            path: '*',
            component: NotFound,
        },
    ],
});

<template>
  <div class="template-summary-container">
    <b-table
     v-if="templateList.length"
     paginated
     per-page="10"
     :selected.sync="selected"
     :data="templateList"
     :columns="columns"
     :hoverable="true"
     @click="onClick">
    </b-table>
    <div v-else>
      <b>No templates available</b>
    </div>
  </div>
</template>

<script>
export default {
    name: 'TemplateSummary',

    props: [
        'templateList'
    ],

    data() {
        return {
            selected: {},
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    searchable: true,
                    sortable: true
                }
            ]
        };
    },

    methods: {
        onClick(row) {
            this.$emit('click', row);
        }
    }
};
</script>

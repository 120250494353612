<template>
  <div class="view campaigns-view container">
    <div class="columns align-items-flex-end">
      <div class="column">
        <h1 class="title">Campaigns</h1>
        <h2 class="subtitle">
          Create and edit campaigns
          <span @click="showHelp">
            <b-icon
             icon="info-circle"
             type="is-primary"
             size="is-small">
            </b-icon>
          </span>
        </h2>
      </div>
      <div class="column">
        <b-button
         v-if="!campaignFormActive"
         class="is-pulled-right"
         type="is-primary"
         @click="onButtonClick">
          New Campaign
        </b-button>
      </div>
    </div>
    <div>
      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
      <CampaignSummary
       v-if="!campaignFormActive"
       :campaignList="campaignList"
       @click="onSummaryClick">
      </CampaignSummary>
      <CampaignForm
       v-if="campaignFormActive"
       :campaign="campaign"
       :tagList="tagList"
       :templateList="templateList"
       @submit="onFormSubmit"
       @cancel="onFormCancel">
      </CampaignForm>
    </div>
  </div>
</template>

<script>
import CampaignForm from '../../components/CampaignForm';
import CampaignSummary from '../../components/CampaignSummary';
import campaigns from '../../services/campaigns';
import tags from '../../services/tags';
import templates from '../../services/templates';

export default {
    name: 'Campaigns',

    components: {
        CampaignForm,
        CampaignSummary
    },

    data() {
        return {
            isLoading: true,
            campaign: {},
            campaignList: [],
            campaignFormActive: false,
            tagList: [],
            templateList: []
        };
    },

    created() {
        this.isLoading = true;
        templates.getSummary()
            .then(resp => {
                this.templateList = resp.data;
            })
            .then(() => {
                tags.getSummary().then(resp => {
                    this.tagList = resp.data;
                });
            })
            .catch(error => {
                this.isLoading = false;
                this.$buefy.toast.open({
                    message: 'Error: ' + error.response.data.error,
                    type: 'is-danger',
                });
                error(error);
            })
            .then(this.fetchCampaigns);
    },

    methods: {
        onButtonClick() {
            this.campaign = {
                id: 0,
                name: '',
                subject: '',
                htmlContent: '',
                textContent: '',
                templateId: 0,
                tagId: 0,
            };
            this.campaignFormActive = true;
        },

        onSummaryClick(campaign) {
            this.isLoading = true;
            campaigns.get(campaign.id).then(resp => {
                this.campaign = resp.data;
                this.campaignFormActive = true;
            })
                .catch(error => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        onFormCancel() {
            this.campaignFormActive = false;
        },

        onFormSubmit(campaign) {
            this.isLoading = true;
            let service;
            if (campaign.id) {
                if (campaign.delete) {
                    service = campaigns.delete(campaign);
                } else {
                    service = campaigns.update(campaign);
                }
            } else {
                service = campaigns.create(campaign);
            }

            service.then(() => {
                this.campaignFormActive = false;
                return this.fetchCampaigns();
            })
                .catch(error => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                });
        },

        fetchCampaigns() {
            this.isLoading = true;
            campaigns.getSummary().then(resp => {
                this.campaignList = resp.data;
            })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        showHelp() {
            this.$buefy.dialog.alert(`
<div class="content">
<p>A campaign is an email delivered to multiple recipients. Here
you can specify the subject and message content for the email, as
well as which template to use as its base.</p>

<p>You can include dynamic information using tags. Any tag that
appears in the subject line, message content, or template will be
replaced with the replacement values specified when the campaign
is sent.</p>

<p>The following tags are required when sending the campaign and
will thus always be available:</p>

<dl>
 <dt><code>{{firstName}}</code></dt>
 <dd>replaced with the recipient's first/personal name</dd>
 <dt><code>{{lastName}}</code></dt>
 <dd>replaced with the recipient's last/family name</dd>
 <dt><code>{{email}}</code></dt>
 <dd>replaced with the recipient's email address</dd>
</dl>

<p>Tags may be used in the subject line and both HTML and text
content.</p>
</div>`
            );
        }
    }
};
</script>

import axios from 'axios';
import router from '../../router';
import store from '../../store';

export default {
  logout() {
    store.dispatch('auth/clearToken')
        .then(() => {
          // delete refreshToken cookie
          return axios.delete('/api/auth');
        })
        .finally(() => {
          router.push({name: 'Login', params: {internal: true}});
        });
  },
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view password-view container" },
    [
      _c("h1", { staticClass: "title" }, [_vm._v("Account: Change Password")]),
      _vm._v(" "),
      _c("h2", { staticClass: "subtitle" }, [_vm._v("Change your password")]),
      _vm._v(" "),
      _c("PasswordForm")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "snippet-summary-container" },
    [
      _vm.snippetList.length
        ? _c("b-table", {
            attrs: {
              paginated: "",
              "per-page": "10",
              selected: _vm.selected,
              data: _vm.snippetList,
              columns: _vm.columns,
              hoverable: true
            },
            on: {
              "update:selected": function($event) {
                _vm.selected = $event
              },
              click: _vm.onClick
            }
          })
        : _c("div", [_c("b", [_vm._v("No snippets available")])])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
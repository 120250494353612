<template>
  <form>
    <b-field class="file">
      <b-upload v-model="file" class="file-label" @input="onFileInput">
        <span class="button is-primary">Upload CSV</span>
      </b-upload>
    </b-field>
    <b-table
     v-if="columns.length"
     paginated
     per-page="10"
     :data="rows"
     :columns="columns">
    </b-table>
  </form>
</template>

<script>
import Papa from 'papaparse/papaparse.min.js';

export default {
    name: 'CsvUploadForm',

    data() {
        return {
            file: null,
            columns: [],
            rows: []
        };
    },

    methods: {
        onFileInput() {
            const reader = new FileReader();
            reader.onload = (evt) => this.parseCsv(evt.target.result);
            reader.readAsText(this.file);
        },

        parseCsv(csvText) {
            let csvArr = Papa.parse(csvText, {header: true, skipEmptyLines: 'greedy'});
            this.rows = csvArr.data;


            this.columns = [];
            let reqFields = [0, 0, 0];
            csvArr.meta.fields.forEach(field => {
                this.columns.push({field: field, label: field, sortable: true});

                // firstName, lastName, and email are required
                switch (field.toLowerCase()) {
                case 'firstname': reqFields[0] = 1; break;
                case 'lastname':  reqFields[1] = 1; break;
                case 'email':     reqFields[2] = 1; break;
                }
            });

            if (reqFields.reduce((sum, a) => sum + a, 0) != reqFields.length) {
                this.$buefy.toast.open({
                    message: 'Error: Missing required fields',
                    type: 'is-danger'
                });
            }
            
            this.$emit('submit', csvArr.data);
        }
    }
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "campaign-status-container" },
    [
      _vm.reportData.length
        ? _c(
            "b-table",
            {
              attrs: { paginated: "", "per-page": "20", data: _vm.reportData }
            },
            [
              _c("b-table-column", {
                attrs: { label: "Status", field: "status" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "b-tag",
                            {
                              attrs: {
                                type:
                                  props.row.status == "completed"
                                    ? "is-success"
                                    : "is-warning"
                              }
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(props.row.status) +
                                  "\n      "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  160825702
                )
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { label: "Campaign", field: "campaignName" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n      " +
                              _vm._s(props.row.campaignName) +
                              "\n    "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2542118564
                )
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { label: "Queued", field: "tsQueued" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n      " + _vm._s(props.row.tsQueued) + "\n    "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4103845167
                )
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { label: "Completed", field: "tsCompleted" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n      " +
                              _vm._s(props.row.tsCompleted) +
                              "\n    "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2162732567
                )
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { label: "Recipients", field: "qtyRecipients" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n      " +
                              _vm._s(props.row.qtyRecipients) +
                              "\n    "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2575648905
                )
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { label: "Failed", field: "qtyFailed" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "div",
                            {
                              class: props.row.qtyFailed
                                ? "has-text-danger"
                                : ""
                            },
                            [
                              _vm._v(
                                "\n      " +
                                  _vm._s(
                                    props.row.tsCompleted
                                      ? props.row.qtyFailed
                                      : ""
                                  ) +
                                  "\n      "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3675532057
                )
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { label: "User", field: "accountName" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n      " +
                              _vm._s(props.row.accountName) +
                              "\n    "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  168305467
                )
              })
            ],
            1
          )
        : _c("div", [_c("b", [_vm._v("No data available")])])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
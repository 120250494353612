<template>
  <div class="campaign-status-container">
    <b-table
     v-if="reportData.length"
     paginated
     per-page="20"
     :data="reportData">
      <b-table-column label="Status" field="status" v-slot="props">
        <b-tag :type="props.row.status == 'completed' ? 'is-success' : 'is-warning'">
          {{ props.row.status }}
        </b-tag>
      </b-table-column>
      <b-table-column label="Campaign" field="campaignName" v-slot="props">
        {{ props.row.campaignName }}
      </b-table-column>
      <b-table-column label="Queued" field="tsQueued" v-slot="props">
        {{ props.row.tsQueued }}
      </b-table-column>
      <b-table-column label="Completed" field="tsCompleted" v-slot="props">
        {{ props.row.tsCompleted }}
      </b-table-column>
      <b-table-column label="Recipients" field="qtyRecipients" v-slot="props">
        {{ props.row.qtyRecipients }}
      </b-table-column>
      <b-table-column label="Failed" field="qtyFailed" v-slot="props">
        <div :class="props.row.qtyFailed ? 'has-text-danger' : ''">
        {{ props.row.tsCompleted ? props.row.qtyFailed : '' }}
        </div>
      </b-table-column>
      <b-table-column label="User" field="accountName" v-slot="props">
        {{ props.row.accountName }}
      </b-table-column>
    </b-table>
    <div v-else>
     <b>No data available</b>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CampaignStatus',

    props: [
        'reportData'
    ]
};
</script>

import axios from 'axios';

export default {
  async getSummary() {
    return axios.get('/api/tags');
  },
  async get(id) {
    return axios.get(`/api/tags/${id}`);
  },
  async create(tag) {
    return axios.post('/api/tags', tag);
  },
  async update(tag) {
    return axios.put(`/api/tags/${tag.id}`, tag);
  },
  async delete(tag) {
    return axios.delete(`/api/tags/${tag.id}`);
  }
}

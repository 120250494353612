<template>
  <div class="view not-found-view container">
    <h1 class="title">404 Page Not Found</h1>
  </div>
</template>

<script>
export default {
    name: 'NotFound',
};
</script>

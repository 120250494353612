var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view tags-view container" }, [
    _c("div", { staticClass: "columns align-items-flex-end" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          !_vm.tagFormActive
            ? _c(
                "b-button",
                {
                  staticClass: "is-pulled-right",
                  attrs: { type: "is-primary" },
                  on: { click: _vm.onButtonClick }
                },
                [_vm._v("\n        New Tag\n      ")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("b-loading", {
          attrs: { "is-full-page": false },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        }),
        _vm._v(" "),
        !_vm.tagFormActive
          ? _c("TagSummary", {
              attrs: { tagList: _vm.tagList },
              on: { click: _vm.onSummaryClick }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.tagFormActive
          ? _c("TagForm", {
              attrs: { tag: _vm.tag },
              on: { submit: _vm.onFormSubmit, cancel: _vm.onFormCancel }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Tags")]),
      _vm._v(" "),
      _c("h2", { staticClass: "subtitle" }, [
        _vm._v("\n        Manage tags\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
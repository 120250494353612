<template>
  <div class="snippet-summary-container">
    <b-table
     v-if="snippetList.length"
     paginated
     per-page="10"
     :selected.sync="selected"
     :data="snippetList"
     :columns="columns"
     :hoverable="true"
     @click="onClick">
    </b-table>
    <div v-else>
      <b>No snippets available</b>
    </div>
  </div>
</template>

<script>
export default {
    name: 'SnippetSummary',

    props: [
        'snippetList'
    ],

    data() {
        return {
            selected: {},
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    searchable: true,
                    sortable: true
                },
                {
                    label: 'Description',
                    field: 'description',
                    searchable: true,
                    sortable: true
                }
            ]
        };
    },

    methods: {
        onClick(row) {
            this.$emit('click', row);
        }
    }
};
</script>

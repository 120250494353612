var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar is-primary" }, [
    _c(
      "div",
      { staticClass: "navbar-brand" },
      [
        _c(
          "router-link",
          {
            staticClass: "navbar-item brand-text",
            attrs: { to: { name: "Home" } }
          },
          [_c("h1", [_vm._v("Home")])]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "navbar-burger burger",
            on: {
              click: function($event) {
                _vm.burgerOpen = !_vm.burgerOpen
              }
            }
          },
          [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "navbar-menu", class: _vm.burgerOpen ? "is-active" : "" },
      [
        _c(
          "div",
          { staticClass: "navbar-start" },
          [
            _c(
              "router-link",
              {
                staticClass: "navbar-item",
                attrs: { to: { name: "Campaigns" } }
              },
              [_vm._v("Campaigns")]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "navbar-item",
                attrs: { to: { name: "Templates" } }
              },
              [_vm._v("Templates")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "navbar-item has-dropdown is-hoverable" },
              [
                _c("div", { staticClass: "navbar-link" }, [_vm._v("System")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "navbar-dropdown" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "navbar-item",
                        attrs: { to: { name: "Accounts" } }
                      },
                      [_vm._v("Accounts")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "navbar-item",
                        attrs: { to: { name: "Snippets" } }
                      },
                      [_vm._v("Snippets")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "navbar-item",
                        attrs: { to: { name: "Tags" } }
                      },
                      [_vm._v("Tags")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "navbar-item",
                        attrs: { to: { name: "Logs" } }
                      },
                      [_vm._v("Logs")]
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "navbar-item has-dropdown is-hoverable" },
              [
                _c("div", { staticClass: "navbar-link" }, [_vm._v("Account")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "navbar-dropdown" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "navbar-item",
                        attrs: { to: { name: "Password" } }
                      },
                      [_vm._v("Password")]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "navbar-end" }, [
          _c(
            "a",
            {
              staticClass: "navbar-item",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.logout($event)
                }
              }
            },
            [_vm._v("Logout")]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
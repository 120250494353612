<template>
  <div class="view login-view">
    <div class="group">
      <div class="logo">
        <img
         src="/img/logo.png"
         width="300"
         alt="Sendilo">
      </div>
      <div class="box">
        <LoginForm></LoginForm>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '../../components/LoginForm';

export default {
    name: 'Login',

    components: {
        LoginForm
    }
};
</script>

<style scoped>
.login-view {
  background-color: #f4f5f7;
  height: 100vh;
}

.group {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  width: 500px;
}

.logo {
  margin-bottom: 20px;
  text-align: center;
}
</style>

<template>
  <div class="account-summary-container">
    <b-table
     v-if="accountList.length"
     paginated
     per-page="10"
     :selected.sync="selected"
     :data="accountList"
     :hoverable="true"
     @click="onClick">
      <b-table-column
       label="Name"
       field="name"
       searchable
       sortable
       v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
       label="Email"
       field="email"
       searchable
       sortable
       v-slot="props">
        {{ props.row.email }}
      </b-table-column>
      <b-table-column
       label="Active"
       field="isActive"
       searchable
       sortable
       v-slot="props">
        {{ parseInt(props.row.isActive, 10) === 1 ? "Yes" : "No" }}
      </b-table-column>
    </b-table>
    <div v-else>
      <b>No accounts available</b>
    </div>
  </div>
</template>

<script>
export default {
    name: 'AccountSummary',

    props: [
        'accountList'
    ],

    data() {
        return {
            selected: {}
        };
    },

    methods: {
        onClick(row) {
            this.$emit('click', row);
        }
    }
};
</script>

<template>
  <div class="tag-summary-container">
    <b-table
     v-if="tagList.length"
     paginated
     per-page="10"
     :selected.sync="selected"
     :data="tagList"
     :hoverable="true"
     @click="onClick">
      <b-table-column
       label="Tag"
       field="name"
       searchable
       sortable
       v-slot="props">
        <b-tag
         :style="{color: props.row.fgColor, backgroundColor: props.row.bgColor}">
         {{ props.row.name }}
        </b-tag>
      </b-table-column>
    </b-table>
    <div v-else>
      <b>No tags available</b>
    </div>
  </div>
</template>

<script>
export default {
    name: 'TagSummary',

    props: [
        'tagList'
    ],

    data() {
        return {
            selected: {}
        };
    },

    methods: {
        onClick(row) {
            this.$emit('click', row);
        }
    }
};
</script>

<template>
  <form>
    <b-field label="Name">
      <b-input
       type="text"
       v-model="form.name"
       autocomplete="off"
       maxlength="100"
       :has-counter="false"
       required>
      </b-input>
    </b-field>
    <b-field label="Email">
      <b-input
       type="email"
       v-model="form.email"
       autocomplete="off"
       maxlength="100"
       :has-counter="false"
       required>
      </b-input>
    </b-field>
    <b-field
     v-if="!form.id"
     label="Password">
      <b-input
       type="password"
       v-model="form.password"
       autocomplete="off"
       maxlength="100"
       :has-counter="false"
       required>
      </b-input>
    </b-field>
    <b-field v-if="userId == form.id">
      <p><router-link :to="{name: 'Password'}">Update Password</router-link></p>
    </b-field>
    <div v-else>
      <b-field>
        <b-switch
         v-model="form.isActive"
         true-value="1"
         false-value="0"
         type="is-success">
          Active
        </b-switch>
      </b-field>
      <b-field v-if="form.id">
        <b-checkbox
         v-model="form.delete"
         autocomplete="off">
          Delete
        </b-checkbox>
      </b-field>
    </div>
    <b-field grouped>
      <p class="control">
        <b-button
         label="Submit"
         :type="form.delete ? 'is-danger' : 'is-primary'"
         @click="onSubmit">
        </b-button>
      </p>
      <p class="control">
        <b-button
         label="Cancel"
         type="is-text"
         @click="onCancel">
        </b-button>
      </p>
    </b-field>
  </form>
</template>

<script>
export default {
    name: 'AccountForm',

    props: [
        'userId',
        'account'
    ],

    data() {
        return {
            form: {}
        };
    },

    created() {
        this.form = {
            id: this.account.id,
            name: this.account.name,
            email: this.account.email,
            password: '',
            isActive: this.account.isActive,
            delete: false
        };
    },

    methods: {
        onSubmit() {
            this.$emit('submit', this.form);
        },

        onCancel() {
            this.$emit('cancel');
        }
    }
};
</script>

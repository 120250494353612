var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    staticStyle: { height: "95vh", width: "100%" },
    attrs: { srcdoc: _vm.previewContent }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="view templates-view container">
    <div class="columns align-items-flex-end">
      <div class="column">
        <h1 class="title">Templates</h1>
        <h2 class="subtitle">
          Manage templates
          <span @click="showHelp">
            <b-icon
             icon="info-circle"
             type="is-primary"
             size="is-small">
            </b-icon>
          </span>
        </h2>
      </div>
      <div class="column">
        <b-button
         v-if="!templateFormActive"
         class="is-pulled-right"
         type="is-primary"
         @click="onButtonClick">
          New Template
        </b-button>
      </div>
    </div>
    <div>
      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
      <TemplateSummary
       v-if="!templateFormActive"
       :templateList="templateList"
       @click="onSummaryClick">
      </TemplateSummary>
      <TemplateForm
       v-if="templateFormActive"
       :template="template"
       @submit="onFormSubmit"
       @cancel="onFormCancel">
      </TemplateForm>
    </div>
  </div>
</template>

<script>
import TemplateForm from '../../components/TemplateForm';
import TemplateSummary from '../../components/TemplateSummary';
import templates from '../../services/templates';

export default {
    name: 'Templates',

    components: {
        TemplateForm,
        TemplateSummary
    },

    data() {
        return {
            isLoading: true,
            template: {},
            templateList: [],
            templateFormActive: false
        };
    },

    created() {
        this.fetchTemplates();
    },

    methods: {
        onButtonClick() {
            this.template = {
                id: 0,
                name: '',
                htmlContent: '',
                textContent: ''
            };
            this.templateFormActive = true;
        },

        onSummaryClick(template) {
            this.isLoading = true;
            templates.get(template.id).then(resp => {
                this.template = resp.data;
                this.templateFormActive = true;
            })
                .catch(error => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        onFormCancel() {
            this.templateFormActive = false;
        },

        onFormSubmit(template) {
            this.isLoading = true;
            let service;
            if (template.id) {
                if (template.delete) {
                    service = templates.delete(template);
                } else {
                    service = templates.update(template);
                }
            } else {
                service = templates.create(template);
            }

            service.then(() => {
                this.templateFormActive = false;
                return this.fetchTemplates();
            })
                .catch(error => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                });
        },

        fetchTemplates() {
            this.isLoading = true;
            templates.getSummary().then(resp => {
                this.templateList = resp.data;
            })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        showHelp() {
            this.$buefy.dialog.alert(`
<div class="content">
<p>Templates provide the base appearance for your email campaign.
Here you can define define styles, header and footer details, and
other information common to multiple campaigns.</p>

<p>You can include dynamic information in the template using tags.
Any tag appearing in a template is replaced with the replacement
values specified when the campaign is sent.</p>

<p>Two tags special to templates are <code>{{content}}</code> and
<code>{{subject}}</code>.</p>

<dl>
 <dt><code>{{content}}</code></dt>
 <dd>replaced with the main content of the campaign</dd>
 <dt><code>{{subject}}</code></dt>
 <dd>replaced with the campaign’s email subject line</dd>
</dl>

<p>Tags may be used in both HTML and text content.</p>
</div>`
            );
        }
    }
};
</script>

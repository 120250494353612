<template>
  <div id="app">
    <TheNavbar v-if="!isExpired"></TheNavbar>
    <main class="main">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import TheNavbar from './components/TheNavbar';

export default {
    name: 'App',

    components: {
        TheNavbar
    },

    computed: {
        ...mapGetters('auth', ['isExpired'])
    }
};
</script>

<style>
@import "bulma/sass/utilities/_all";

$primary: #209cee;
$primary-invert: findColorInvert($primary);

@use "sass:map";
$colors: map-merge($colors, (
  "primary": ($primary, $primary-invert)
));

$table-head-background-color: $background;

@import "bulma";
@import "buefy/src/scss/buefy";

textarea {
  font-family: monospace;
}

.view {
  margin-bottom: 2em;
}

.message .message-body {
  border-width: 0;
}
</style>

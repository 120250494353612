var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view reports-view container" }, [
    _c("h1", { staticClass: "title" }, [_vm._v("Logs")]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("b-loading", {
          attrs: { "is-full-page": false },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        }),
        _vm._v(" "),
        _c("CampaignStatus", { attrs: { reportData: _vm.reportData } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import axios from 'axios';

export default {
    async getSummary() {
        return axios.get('/api/accounts');
    },
    async get(id) {
        return axios.get(`/api/accounts/${id}`);
    },
    async create(acct) {
        return axios.post('/api/accounts', acct);
    },
    async update(acct) {
        return axios.put(`/api/accounts/${acct.id}`, acct);
    },
    async delete(acct) {
        return axios.delete(`/api/accounts/${acct.id}`);
    }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view login-view" }, [
    _c("div", { staticClass: "group" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [_c("LoginForm")], 1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: "/img/logo.png", width: "300", alt: "Sendilo" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
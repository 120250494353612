var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c(
        "b-field",
        { attrs: { label: "Name" } },
        [
          _c("b-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              maxlength: "100",
              "has-counter": false,
              required: ""
            },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { position: "is-right" } },
        [
          _c("b-button", {
            staticClass: "is-pulled-right",
            attrs: { label: "Preview", type: "is-text", size: "is-small" },
            on: { click: _vm.previewHtml }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: "HTML Content" } },
        [
          _c("b-input", {
            attrs: {
              type: "textarea",
              rows: "10",
              autocomplete: "off",
              required: ""
            },
            model: {
              value: _vm.form.htmlContent,
              callback: function($$v) {
                _vm.$set(_vm.form, "htmlContent", $$v)
              },
              expression: "form.htmlContent"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { position: "is-right" } },
        [
          _c("b-button", {
            staticClass: "is-pulled-right",
            attrs: { label: "Preview", type: "is-text", size: "is-small" },
            on: { click: _vm.previewText }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { position: "is-right" } },
        [
          _c("b-button", {
            staticClass: "is-pulled-right",
            attrs: { label: "Word Wrap", type: "is-text", size: "is-small" },
            on: { click: _vm.wrap }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: "Text Content" } },
        [
          _c("b-input", {
            attrs: {
              type: "textarea",
              rows: "10",
              autocomplete: "off",
              required: ""
            },
            model: {
              value: _vm.form.textContent,
              callback: function($$v) {
                _vm.$set(_vm.form, "textContent", $$v)
              },
              expression: "form.textContent"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.form.id
        ? _c(
            "b-field",
            [
              _c(
                "b-checkbox",
                {
                  attrs: { autocomplete: "off" },
                  model: {
                    value: _vm.form.delete,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "delete", $$v)
                    },
                    expression: "form.delete"
                  }
                },
                [_vm._v("\n      Delete\n    ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-field", { attrs: { grouped: "" } }, [
        _c(
          "p",
          { staticClass: "control" },
          [
            _c("b-button", {
              attrs: {
                label: "Submit",
                type: _vm.form.delete ? "is-danger" : "is-primary"
              },
              on: { click: _vm.onSubmit }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "control" },
          [
            _c("b-button", {
              attrs: { label: "Cancel", type: "is-text" },
              on: { click: _vm.onCancel }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.showPreview,
            callback: function($$v) {
              _vm.showPreview = $$v
            },
            expression: "showPreview"
          }
        },
        [
          _c("preview-view", {
            attrs: { type: _vm.previewType, content: _vm.previewContent }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
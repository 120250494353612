<template>
  <div class="view snippets-view container">
    <div class="columns align-items-flex-end">
      <div class="column">
        <h1 class="title">Snippets</h1>
        <h2 class="subtitle">
          Manage snippets
        </h2>
      </div>
      <div class="column">
        <b-button
         v-if="!snippetFormActive"
         class="is-pulled-right"
         type="is-primary"
         @click="onButtonClick">
          New Snippet
        </b-button>
      </div>
    </div>
    <div>
      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
      <SnippetSummary
       v-if="!snippetFormActive"
       :snippetList="snippetList"
       @click="onSummaryClick">
      </SnippetSummary>
      <SnippetForm
       v-if="snippetFormActive"
       :snippet="snippet"
       @submit="onFormSubmit"
       @cancel="onFormCancel">
      </SnippetForm>
    </div>
  </div>
</template>

<script>
import SnippetForm from '../../components/SnippetForm';
import SnippetSummary from '../../components/SnippetSummary';
import snippets from '../../services/snippets';

export default {
    name: 'Snippets',

    components: {
        SnippetForm,
        SnippetSummary
    },

    data() {
        return {
            isLoading: true,
            snippet: {},
            snippetList: [],
            snippetFormActive: false
        };
    },

    created() {
        this.fetchSnippets();
    },

    methods: {
        onButtonClick() {
            this.snippet = {
                id: 0,
                name: '',
                description: '',
                content: ''
            };
            this.snippetFormActive = true;
        },

        onSummaryClick(snippet) {
            this.isLoading = true;
            snippets.get(snippet.id).then(resp => {
                this.snippet = resp.data;
                this.snippetFormActive = true;
            })
                .catch(error => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        onFormCancel() {
            this.snippetFormActive = false;
        },

        onFormSubmit(snippet) {
            this.isLoading = true;
            let service;
            if (snippet.id) {
                if (snippet.delete) {
                    service = snippets.delete(snippet);
                } else {
                    service = snippets.update(snippet);
                }
            } else {
                service = snippets.create(snippet);
            }

            service.then(() => {
                this.snippetFormActive = false;
                return this.fetchSnippets();
            })
                .catch(error => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                });
        },

        fetchSnippets() {
            this.isLoading = true;
            snippets.getSummary().then(resp => {
                this.snippetList = resp.data;
            })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>

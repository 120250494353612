var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c(
        "b-field",
        { staticClass: "file" },
        [
          _c(
            "b-upload",
            {
              staticClass: "file-label",
              on: { input: _vm.onFileInput },
              model: {
                value: _vm.file,
                callback: function($$v) {
                  _vm.file = $$v
                },
                expression: "file"
              }
            },
            [
              _c("span", { staticClass: "button is-primary" }, [
                _vm._v("Upload CSV")
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.columns.length
        ? _c("b-table", {
            attrs: {
              paginated: "",
              "per-page": "10",
              data: _vm.rows,
              columns: _vm.columns
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <form>
    <b-field label="Name">
      <b-input
       type="text"
       v-model="form.name"
       autocomplete="off"
       maxlength="100"
       :has-counter="false"
       required>
      </b-input>
    </b-field>
    <b-field label="Description">
      <b-input
       type="text"
       v-model="form.description"
       autocomplete="off"
       maxlength="100"
       :has-counter="false"
       required>
      </b-input>
    </b-field>
    <b-field label="Content">
      <editor
       v-model="form.content"
       :init="editorInit">
      </editor>
    </b-field>
    <b-field v-if="form.id">
      <b-checkbox
       v-model="form.delete"
       autocomplete="off">
        Delete
      </b-checkbox>
    </b-field>
    <b-field grouped>
      <p class="control">
        <b-button
         label="Submit"
         :type="form.delete ? 'is-danger' : 'is-primary'"
         @click="onSubmit">
        </b-button>
      </p>
      <p class="control">
        <b-button
         label="Cancel"
         type="is-text"
         @click="onCancel">
        </b-button>
      </p>
    </b-field>
  </form>
</template>

<script>
import editor from '@tinymce/tinymce-vue';

export default {
    name: 'SnippetForm',

    props: [
        'snippet'
    ],

    components: {
        editor
    },

    data() {
        return {
            form: {},
            editorInit: {
                theme: 'modern',
                height: 450,
                plugins: 'lists link image code table contextmenu imagetools wordcount template',
                menubar: false,
                toolbar: 'styleselect | bold italic | bullist numlist table | link image template | code',
                image_advtab: true,
                convert_urls: false,
                valid_elements: '*[*]',
                branding: false
            }
        };
    },

    created() {
        this.form = {
            id: this.snippet.id, 
            name: this.snippet.name,
            description: this.snippet.description,
            content: this.snippet.content,
            delete: false
        };
    },

    methods: {
        onSubmit() {
            this.$emit('submit', this.form);
        },

        onCancel() {
            this.$emit('cancel');
        }
    }
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag-summary-container" },
    [
      _vm.tagList.length
        ? _c(
            "b-table",
            {
              attrs: {
                paginated: "",
                "per-page": "10",
                selected: _vm.selected,
                data: _vm.tagList,
                hoverable: true
              },
              on: {
                "update:selected": function($event) {
                  _vm.selected = $event
                },
                click: _vm.onClick
              }
            },
            [
              _c("b-table-column", {
                attrs: {
                  label: "Tag",
                  field: "name",
                  searchable: "",
                  sortable: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "b-tag",
                            {
                              style: {
                                color: props.row.fgColor,
                                backgroundColor: props.row.bgColor
                              }
                            },
                            [
                              _vm._v(
                                "\n       " +
                                  _vm._s(props.row.name) +
                                  "\n      "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1499058294
                )
              })
            ],
            1
          )
        : _c("div", [_c("b", [_vm._v("No tags available")])])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
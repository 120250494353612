<template>
  <div class="password-form">
    <form @submit.prevent="onSubmit">
      <div class="field">
        <label class="label">Current Password</label>
        <div class="control">
          <input v-model="form.oldPassword" type="password" class="input" maxlength="100"/>
        </div>
      </div>
      <div class="field">
        <label class="label">New Pasword</label>
        <div class="control">
          <input v-model="form.newPassword" type="password" class="input" maxlength="100"/>
        </div>
      </div>
      <div class="field">
        <label class="label">Confirm Pasword</label>
        <div class="control">
          <input v-model="form.confirmPassword" type="password" class="input" maxlength="100"/>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <input type="submit" value="Submit" class="button is-primary"/>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
    name: 'PasswordForm',

    data() {
        return {
            form: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            }
        };
    },

    methods: {
        onSubmit() {
            if (this.form.newPassword != this.form.confirmPassword) {
                this.$buefy.toast.open({
                    message: 'Error: New and confirm passwords do not match',
                    type: 'is-danger'
                });
                return;
            }
            this.$http.post('/api/password', this.form)
                .then(() => {
                    this.$buefy.toast.open({
                        message: 'Password change successful',
                        type: 'is-success'
                    });
                    this.$router.push({name: 'Home'});
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                    console.error(error);
                });
        },
    },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "campaign-summary-container" },
    [
      _vm.campaignList.length
        ? _c(
            "b-table",
            {
              attrs: {
                paginated: "",
                "per-page": "10",
                selected: _vm.selected,
                data: _vm.campaignList,
                hoverable: true
              },
              on: {
                "update:selected": function($event) {
                  _vm.selected = $event
                },
                click: _vm.onClick
              }
            },
            [
              _c("b-table-column", {
                attrs: {
                  label: "Name",
                  field: "name",
                  searchable: "",
                  sortable: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _vm._v("\n      " + _vm._s(props.row.name) + "\n    ")
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  984597786
                )
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  label: "Tag",
                  field: "tag.name",
                  searchable: "",
                  sortable: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.tagId != 0
                            ? _c(
                                "b-tag",
                                {
                                  style: {
                                    color: props.row.tag.fgColor,
                                    backgroundColor: props.row.tag.bgColor
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n        " +
                                      _vm._s(props.row.tag.name) +
                                      "\n      "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2013803234
                )
              })
            ],
            1
          )
        : _c("div", [_c("b", [_vm._v("No campaigns available")])])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
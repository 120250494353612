<template>
  <div class="view accounts-view container">
    <div class="columns align-items-flex-end">
      <div class="column">
        <h1 class="title">Accounts</h1>
        <h2 class="subtitle">
          Manage accounts
        </h2>
      </div>
      <div class="column">
        <b-button
         v-if="!accountFormActive"
         class="is-pulled-right"
         type="is-primary"
         @click="onButtonClick">
          New Account
        </b-button>
      </div>
    </div>
    <div>
      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
      <AccountSummary
       v-if="!accountFormActive"
       :accountList="accountList"
       @click="onSummaryClick">
      </AccountSummary>
      <AccountForm
       v-if="accountFormActive"
       :userId="userId"
       :account="account"
       @submit="onFormSubmit"
       @cancel="onFormCancel">
      </AccountForm>
    </div>
  </div>
</template>

<script>
import AccountForm from '../../components/AccountForm';
import AccountSummary from '../../components/AccountSummary';
import accounts from '../../services/accounts';

export default {
    name: 'Accounts',

    components: {
        AccountForm,
        AccountSummary
    },

    data() {
        return {
            isLoading: true,
            account: {},
            accountList: [],
            accountFormActive: false,
            userId: this.$store.getters['auth/userId']
        };
    },

    created() {
        this.fetchAccounts();
    },

    methods: {
        onButtonClick() {
            this.account = {
                id: 0,
                name: '',
                email: '',
                isActive: '1',
            },
            this.accountFormActive = true;
        },

        onSummaryClick(account) {
            this.isLoading = true;
            accounts.get(account.id).then(resp => {
                this.account = resp.data;
                this.accountFormActive = true;
            })
                .catch(error => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        onFormCancel() {
            this.accountFormActive = false;
        },

        onFormSubmit(account) {
            this.isLoading = true;
            let service;
            if (account.id) {
                if (account.delete) {
                    service = accounts.delete(account);
                } else {
                    service = accounts.update(account);
                }
            } else {
                service = accounts.create(account);
            }

            service.then(() => {
                this.accountFormActive = false;
                return this.fetchAccounts();
            })
                .catch(error => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                });
        },

        fetchAccounts() {
            this.isLoading = true;
            accounts.getSummary().then(resp => {
                this.accountList = resp.data;
            })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                    error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>

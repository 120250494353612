<template>
  <form>
    <b-field label="Name">
      <b-input
       type="text"
       v-model="form.name"
       autocomplete="off"
       maxlength="100"
       :has-counter="false"
       required>
      </b-input>
    </b-field>
    <b-field label="Subject">
      <b-input
       type="text"
       v-model="form.subject"
       autocomplete="off"
       maxlength="50"
       :has-counter="false"
       required>
      </b-input>
    </b-field>
    <b-field label="Template">
      <b-select placeholder="Select a template"
       type="number"
       v-model="form.templateId"
       autocomplete="off"
       required>
        <option v-for="template in templateList"
         :key="template.id"
         :value="template.id">
          {{ template.name }}
        </option>
      </b-select>
    </b-field>
    <b-field position="is-right">
      <b-button
       label="Preview"
       type="is-text"
       class="is-pulled-right"
       size="is-small"
       @click="previewHtml">
      </b-button>
    </b-field>
    <b-field position="is-right">
      <b-button
       label="Copy to Text Content"
       type="is-text"
       class="is-pulled-right"
       size="is-small"
       @click="copy">
      </b-button>
    </b-field>
    <b-field label="HTML Content">
      <editor
       v-model="form.htmlContent"
       :init="editorInit"
       ref="tmce">
      </editor>
    </b-field>
    <b-field position="is-right">
      <b-button
       label="Preview"
       type="is-text"
       class="is-pulled-right"
       size="is-small"
       @click="previewText">
      </b-button>
    </b-field>
    <b-field position="is-right">
      <b-button
       label="Word Wrap"
       type="is-text"
       class="is-pulled-right"
       size="is-small"
       @click="wrap">
      </b-button>
    </b-field>
    <b-field label="Text Content">
      <b-input
       type="textarea"
       rows="10"
       v-model="form.textContent"
       autocomplete="off"
       required>
      </b-input>
    </b-field>
    <b-field label="Tag">
      <b-select placeholder="No tag"
       v-model="form.tagId"
       autocomplete="off"
       required>
        <option key="0" value="0">No tag</option>
        <option v-for="tag in tagList"
         :key="tag.id"
         :value="tag.id">
          {{ tag.name }}
        </option>
      </b-select>
    </b-field>
    <b-field v-if="form.id">
      <b-checkbox
       v-model="form.delete"
       autocomplete="off">
        Delete
      </b-checkbox>
    </b-field>
    <b-field grouped>
      <p class="control">
        <b-button
         label="Submit"
         :type="form.delete ? 'is-danger' : 'is-primary'"
         @click="onSubmit">
        </b-button>
      </p>
      <p class="control">
        <b-button
         label="Cancel"
         type="is-text"
         @click="onCancel">
        </b-button>
      </p>
    </b-field>
    <b-modal v-model="showPreview">
      <preview-view
       :type="previewType"
       :content="previewContent">
      </preview-view>
    </b-modal>
  </form>
</template>

<script>
import templates from '../../services/templates';
import PreviewView from '../PreviewView';
import editor from '@tinymce/tinymce-vue';

export default {
    name: 'CampaignForm',

    props: [
        'campaign',
        'templateList',
        'tagList'
    ],

    components: {
        editor,
        PreviewView
    },

    data() {
        return {
            form: {},
            previewType: 'text',
            previewContent: '',
            showPreview: false,
            editorInit: {
                theme: 'modern',
                height: 450,
                plugins: 'lists link image code table contextmenu imagetools wordcount template',
                menubar: false,
                toolbar: 'styleselect | bold italic | bullist numlist table | link image template | code',
                image_advtab: true,
                convert_urls: false,
                valid_elements: '*[*]',
                branding: false,
                templates: '/api/snippets.json'
            }
        };
    },

    created() {
        this.form = {
            id: this.campaign.id, 
            name: this.campaign.name,
            subject: this.campaign.subject,
            templateId: this.campaign.templateId,
            htmlContent: this.campaign.htmlContent,
            textContent: this.campaign.textContent,
            tagId: this.campaign.tagId,
            delete: false
        };
    },

    methods: {
        // stackoverflow.com/a/51506718
        wrap() {
            this.form.textContent = this.form.textContent.replace(
                /(?![^\n]{1,78}$)([^\n]{1,78})\s/g, '$1\n'
            );
        },

        copy() {
            var txt = this.$refs.tmce.editor.getContent({ format: 'text' });
            txt = txt.replace(/(\r\n|\r|\n){2}/g, '$1$1');
            txt = txt.replace(/(\r\n|\r|\n){3}/g, '$1');
            this.form.textContent = txt;
        },

        preview(contentType) {
            templates.get(this.form.templateId).then(resp => {
                this.previewContent = resp.data[contentType]
                    .replaceAll('{{subject}}', this.form.subject)
                    .replaceAll('{{content}}', this.form[contentType]);
                this.showPreview = true;
            })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: 'Error: ' + error.response.data.error,
                        type: 'is-danger'
                    });
                });
        },

        previewHtml() {
            this.previewType = 'html';
            this.preview('htmlContent');
        },

        previewText() {
            this.previewType = 'text';
            this.preview('textContent');
        },

        onSubmit() {
            this.$emit('submit', this.form);
        },

        onCancel() {
            this.$emit('cancel');
        }
    }
};
</script>

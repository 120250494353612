var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-summary-container" },
    [
      _vm.accountList.length
        ? _c(
            "b-table",
            {
              attrs: {
                paginated: "",
                "per-page": "10",
                selected: _vm.selected,
                data: _vm.accountList,
                hoverable: true
              },
              on: {
                "update:selected": function($event) {
                  _vm.selected = $event
                },
                click: _vm.onClick
              }
            },
            [
              _c("b-table-column", {
                attrs: {
                  label: "Name",
                  field: "name",
                  searchable: "",
                  sortable: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _vm._v("\n      " + _vm._s(props.row.name) + "\n    ")
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  984597786
                )
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  label: "Email",
                  field: "email",
                  searchable: "",
                  sortable: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n      " + _vm._s(props.row.email) + "\n    "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3882795633
                )
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  label: "Active",
                  field: "isActive",
                  searchable: "",
                  sortable: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n      " +
                              _vm._s(
                                parseInt(props.row.isActive, 10) === 1
                                  ? "Yes"
                                  : "No"
                              ) +
                              "\n    "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2854807590
                )
              })
            ],
            1
          )
        : _c("div", [_c("b", [_vm._v("No accounts available")])])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }